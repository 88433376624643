.home {
  width: 100%;
  height: 100%;

  &__background {
    background-image: url(../../assets/images/carPic.jpg);
    width: 100%;
    height: 1500px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    z-index: 10;
    opacity: 0.4;
  }

  &__content {
    left: 0;
    line-height: 200px;
    margin-top: -100px;
    position: absolute;
    text-align: center;
    top: 50%;
    width: 100%;
  }

  &__title {
    color: $secondary;
    text-transform: uppercase;
  }

  &__btn {
    background-color: $accent;
    text-decoration: none;
    color: $secondary;
    padding: 0.9em;
    font-weight: bold;
    border-radius: 10px;
  }

  &__btn:hover {
    background-color: $secondary;
    color: $accent;
    text-decoration: none;
    transition: 0.25s ease-in-out;
  }
}
