* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

$primary: #010606;
$secondary: #fff;
$third: #101522;
$accent: #7b0d1e;
$altBg: #faf9f6;

$font: "Cairo", sans-serif;
$box-shadow: 10px 10px 43px 0px rgba(0, 0, 0, 0.39);

html {
  scroll-behavior: smooth;
}

body {
  font-family: $font;
  line-height: 1.6;
}
