@import url("https://fonts.googleapis.com/css2?family=Cairo&display=swap");

@import "partials/base";
@import "partials/nav";
@import "partials/home";
@import "partials/hero";
@import "partials/about";
@import "partials/releases";
@import "partials/discography";
@import "partials/footer";
