.about {
  &__container {
    color: $secondary;
    background: $primary;

    @media screen and (max-width: 768px) {
      padding: 100px 0;
    }
  }

  &__container2 {
    color: $primary;
    background: $secondary;

    @media screen and (max-width: 768px) {
      padding: 100px 0;
    }
  }

  &__wrapper {
    display: grid;
    z-index: 1;
    height: 860px;
    width: 100%;
    max-width: 1100px;
    margin-right: auto;
    margin-left: auto;
    padding: 0 24px;
    justify-content: center;
  }

  &__column1 {
    margin-bottom: 15px;
    padding: 0 15px;
    grid-area: col1;
  }

  &__column2 {
    margin-bottom: 15px;
    padding: 0 15px;
    grid-area: col2;
  }

  &__row {
    display: grid;
    grid-auto-columns: minmax(auto, 1fr);
    align-items: center;
    grid-template-areas: col2 col1;

    @media screen and (max-width: 768px) {
      grid-template-areas: col1 col1;
    }
  }

  &__textWrapper {
    max-width: 540px;
    padding-top: 0;
    padding-bottom: 60px;
  }

  &__topline {
    color: #01bf71;
    font-size: 16px;
    line-height: 16px;
    font-weight: 700;
    letter-spacing: 1.4px;
    text-transform: uppercase;
    margin-bottom: 16px;
  }

  &__btnWrap {
    display: flex;
    justify-content: flex-start;
  }

  &__button {
    border-radius: 50px;
    background: $primary;
    white-space: nowrap;
    padding: 14px 40px;
    color: $secondary;
    font-size: 16px;
    outline: none;
    border: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.2s ease-in-out;
    text-decoration: none;
    font-weight: bold;

    &:hover {
      transition: all 0.2s ease-in-out;
      background-color: $accent;
    }
  }

  &__heading {
    margin-bottom: 24px;
    font-size: 48px;
    line-height: 1.1;
    font-weight: 600;
    color: $secondary;

    @media screen and (max-width: 480px) {
      font-size: 32px;
    }
  }

  &__subtitle {
    max-width: 440px;
    margin-bottom: 35px;
    font-size: 18px;
    line-height: 24px;
    color: $secondary;
  }

  &__subtitle2 {
    max-width: 440px;
    margin-bottom: 35px;
    font-size: 18px;
    line-height: 24px;
    color: $primary;
  }

  &__imgWrap {
    max-width: 500px;
    height: 100%;
  }

  &__img {
    width: 100%;
    margin: 0 0 10px 0;
    padding-right: 0;
    height: auto;
    box-shadow: 10px 10px 43px 0px rgba(0, 0, 0, 0.18);
  }
}
