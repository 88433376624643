.releases {
  display: flex;
  max-width: 1100px;

  &__topline {
    color: #01bf71;
    font-size: 16px;
    line-height: 16px;
    font-weight: 700;
    letter-spacing: 1.4px;
    text-transform: uppercase;
    margin-top: 50px;
    margin-bottom: -80px;
  }

  @media screen and (max-width: 1100px) {
    max-width: 900px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    grid-gap: 16px;
    padding: 0 50px;
  }
  @media screen and (max-width: 1000px) {
    max-width: 900px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    grid-gap: 16px;
    padding: 0 50px;
  }

  @media screen and (max-width: 768px) {
    max-width: 900px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    grid-gap: 16px;
    padding: 0 50px;
    margin-bottom: -200px;
  }

  &__release {
    padding: 1.2em;
    margin: 1em;
    background-color: $secondary;
    border-radius: 10px;
  }

  &__img {
    width: 200px;
    height: auto;
  }

  &__title {
    color: $primary;
  }

  &__button {
    border-radius: 25px;
    margin-top: 10px;
    background: $primary;
    white-space: nowrap;
    padding: 5px 20px;
    color: $secondary;
    font-size: 14px;
    outline: none;
    border: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: left;
    transition: all 0.2s ease-in-out;
    text-decoration: none;
    font-weight: bold;

    &:hover {
      transition: all 0.2s ease-in-out;
      background-color: $accent;
    }
  }
}
