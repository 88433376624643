.footer {
  background-color: $third;

  &__wrapper {
    padding: 48px 24px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
  }

  &__socialMedia {
    max-width: 900px;
    width: 100%;
  }

  &__socialMediaWrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1000px;
    margin: 40px auto 0 auto;

    @media screen and (max-width: 820px) {
      flex-direction: column;
    }
  }

  &__logo {
    color: $secondary;
    justify-self: start;
    cursor: pointer;
    text-decoration: none;
    font-size: 1.5rem;
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    font-weight: bold;
    text-transform: uppercase;
    flex-direction: column;
  }

  &__logoH1 {
    letter-spacing: 4px;
  }

  &__rights {
    color: $secondary;
    margin-bottom: 16px;
    font-size: 13px;
    font-weight: normal;
    margin-top: 5px;
  }

  &__socialIcons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 240px;
  }

  &__socialIconLink {
    color: $secondary;
    font-size: 24px;
  }
}
