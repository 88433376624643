.navbar {
  background: $primary;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  font-weight: bold;
  width: 100%;
  z-index: 1;

  &__logo {
    color: $secondary;
    justify-self: start;
    margin-left: 20px;
    cursor: pointer;
    text-decoration: none;
    font-size: 1.5rem;
    align-items: center;
    text-transform: uppercase;
  }

  &__active {
    border-bottom: 3px solid $accent;
  }

  &__menu {
    display: grid;
    grid-template-columns: repeat(4, auto);
    grid-gap: 10px;
    list-style: none;
    text-align: center;
    width: 70vw;
    justify-content: end;
    margin-right: 2rem;
  }

  &__item {
    display: flex;
    align-items: center;
    height: 80px;
  }

  &__link {
    color: $secondary;
    text-decoration: none;
    padding: 0.5rem 1rem;
  }

  &__link:hover {
    background-color: $secondary;
    color: $primary;
    border-radius: 4px;
    transition: all 0.2s ease-out;
  }

  &__icon {
    display: none;
  }

  @media (max-width: 990px) {
    &__item {
      position: relative;
    }

    &__menu {
      display: flex;
      flex-direction: column;
      width: 100%;
      position: absolute;
      top: 80px;
      left: -100%;
      opacity: 1;
      transition: all 0.35s ease;
      padding: 50px;
    }

    &__menu.active {
      background-color: $primary;
      left: 0;
      opacity: 0.95;
      transition: all 0.35s ease;
    }

    &__link {
      width: 100%;
      display: flex;
      justify-content: center;
      font-size: 23px;
    }

    &__link:hover {
      background-color: transparent;
      color: $accent;
    }

    &__logo {
      position: absolute;
      top: 0;
      left: 0;
      transform: translate(25%, 50%);
    }

    &__icon {
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      margin-top: -10px;
      transform: translate(-100%, 60%);
      font-size: 1.8rem;
      cursor: pointer;
      color: $secondary;
    }
  }
}
